import * as Sentry from '@sentry/react'

import { ErrorMonitoring } from '@/core/services/error-monitoring'
import { Decamelize } from '@helpers/object'
import { growthbook } from '@/core/services/growthbook'
import { Session } from '@/sessions/components/sessions-body/sessions-table/models'
import { config } from '@/offline/config'

import { BackgroundSyncManagerInterface } from '../interfaces/BackgroundSyncManagerInterface'
import { LocalDataManagerInterface } from '../interfaces/LocalDataManagerInterface'
import { SymmetricKeyManagerInterface } from '../interfaces/SymmetricKeyManagerInterface'
import {
  SYNC_EVENT_TYPES,
  SyncEventBusManagerInterface,
} from '../interfaces/SyncEventBusManagerInterface'
import { iseExistsInCache } from '../helpers'
import { encryptSymmetrically } from '../services/DefaultCryptographyService'
import { Stores } from '../models'
import { getUpcomingSessions } from '../services/getUpcomingSessions'

import SyncEventBusManager from './SyncEventBusManager'

type SessionSnakeCase = Decamelize<Session>

const ONE_HOUR_IN_MILLIS = 60 * 60 * 1000
const RETRYABLE_STATUS_CODES = new Set([408, 425, 429, 500, 502, 503, 504])
const fetchAfterTimeout = (
  url: string,
  timeout: number,
  fetchOptions: RequestInit = { cache: 'reload' },
): Response | PromiseLike<Response> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      fetch(url, fetchOptions).then(resolve).catch(reject)
    }, timeout)
  })
}
const fetchWithRetry = async (url: string, retryAttempts: number) => {
  let count = 0
  let timeout = 1000
  let resp

  try {
    resp = await fetch(url)
  } catch {
    timeout *= 2
    count++
  }

  // retry if fetch throws
  while (!resp && count < retryAttempts) {
    try {
      resp = await fetchAfterTimeout(url, timeout)
    } catch {
      timeout *= 2
      count++
    }
  }

  if (!resp) {
    throw new Error(`Failed to fetch after ${retryAttempts} attempts`)
  }

  // retry if the request failed due to a retryable status code
  while (RETRYABLE_STATUS_CODES.has(resp.status) && count < retryAttempts) {
    resp = await fetchAfterTimeout(url, timeout)

    timeout *= 2
    count++
  }

  return resp
}

export default class BackgroundSyncManager
  implements BackgroundSyncManagerInterface
{
  private interval: number
  private intervalId: number | null
  private syncEventBusManager: SyncEventBusManagerInterface
  private retryAttempts: number
  private symmetricKeyManager: SymmetricKeyManagerInterface
  private localDataManager: LocalDataManagerInterface
  private bundleHash: string
  private sessionSyncPermission: boolean | null = null

  /**
   * Instantiates a new BackgroundSyncManager instance
   * @param localDataManager The manager for interacting with indexDB
   * @param symmetricKeyManager The manager for retrieving and storing a symmetric key
   * @param interval The interval at which to sync with the backend
   * @param retryAttempts The number of times to retry requests to the backend when attempting to cache a response
   */
  constructor(
    localDataManager: LocalDataManagerInterface,
    symmetricKeyManager: SymmetricKeyManagerInterface,
    interval = 1000 * 60 * 60,
    retryAttempts = 3,
  ) {
    this.interval = interval
    this.intervalId = null
    this.syncEventBusManager = new SyncEventBusManager()
    this.retryAttempts = retryAttempts
    this.symmetricKeyManager = symmetricKeyManager
    this.localDataManager = localDataManager
    this.bundleHash = process.env.BUNDLE_HASH || 'bundle' // 'bundle' || 'v4uuid'

    this.setupEventListeners()
  }

  /**
   * Start the periodic sync of data with the backend. Called as part of the
   * offline app initialization process on every page load.
   */
  async startBackgroundSync() {
    if (!this.isUserLoggedIn()) {
      this.localDataManager.deleteData(
        Stores.SymmetricKey,
        config.SYMMETRIC_KEY_ID,
      )
      const cacheName = 'workbox-runtime-' + window.location.origin + '/'
      const cache = await caches.open(cacheName)
      await cache.delete('/api/users/v1/user/symmetric-key')
      window.location.assign('/accounts/login')
      return
    }

    this.syncEventBusManager.dispatch(
      new CustomEvent(SYNC_EVENT_TYPES.START, {
        detail: { message: 'starting background sync' },
      }),
    )

    const oneHourAgo = Date.now() - ONE_HOUR_IN_MILLIS
    let lastUpdatedAt = await this.localDataManager.getLastUpdatedAt()
    const urlExistsInCache = await iseExistsInCache()

    if (!lastUpdatedAt || lastUpdatedAt < oneHourAgo || !urlExistsInCache) {
      try {
        await this.doInitialSync()
        const now = Date.now()
        await this.localDataManager.setLastUpdatedAt(now)
        lastUpdatedAt = now
        this.syncEventBusManager.dispatch(
          new CustomEvent(SYNC_EVENT_TYPES.SUCCESS, {
            detail: { message: 'background sync completed successfully' },
          }),
        )
      } catch (e) {
        this.syncEventBusManager.dispatch(
          new CustomEvent(SYNC_EVENT_TYPES.ERROR, {
            detail: { error: e },
          }),
        )
      }
    } else {
      this.syncEventBusManager.dispatch(
        new CustomEvent(SYNC_EVENT_TYPES.SUCCESS, {
          detail: { message: 'background sync completed successfully' },
        }),
      )
    }

    setTimeout(
      () => {
        // start background sync
        this.intervalId = window.setInterval(async () => {
          this.syncEventBusManager.dispatch(
            new CustomEvent(SYNC_EVENT_TYPES.START, {
              detail: { message: 'starting background sync' },
            }),
          )

          await this.doHourlySync()

          this.syncEventBusManager.dispatch(
            new CustomEvent(SYNC_EVENT_TYPES.SUCCESS, {
              detail: { message: 'background sync completed successfully' },
            }),
          )
        }, this.interval)
      },
      Date.now() - (lastUpdatedAt + ONE_HOUR_IN_MILLIS),
    )
  }

  /**
   * Stop the periodic background sync of data with the backend
   */
  stopBackgroundSync() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
      this.intervalId = null
    } else {
      console.warn(
        'attempted to stop background sync when background sync was not running',
      )
    }
  }

  /**
   * Sync the sessions page and all ISEs it links to.
   * @returns Promise<void>
   */
  private async syncSessions() {
    await this.fetchAndCacheStatics('Sessions page', [
      `/static/assets/js/ise.${this.bundleHash}.js`,
      '/static/js/sessionGeolocation.js',
      '/static/audio/chime.wav',
    ])

    // DBW 8/6/24: We don't use the fetchAndCache methods because we do post-processing
    // of the sessions page after we put it in the cache, and fetchAndCache doesn't \
    // return the responses.
    const resp = await fetchWithRetry('/sessions/', this.retryAttempts)
    if (resp.status >= 400) {
      this.syncEventBusManager.dispatch(
        new CustomEvent(SYNC_EVENT_TYPES.ERROR, {
          detail: { message: 'Failed to sync sessions page' },
        }),
      )
      return
    }
    const cacheTwyllOffline = await caches.open('twyll-offline')
    cacheTwyllOffline.put('/sessions/', resp.clone())

    const body = await resp.text()
    const parser = new DOMParser()
    const html = parser.parseFromString(body, 'text/html')

    const links = Array.from(
      html.querySelectorAll('a[data-testid="sessionIdentityButton"]'),
    )
      .map((link) => link.getAttribute('href'))
      .filter((link) => link !== null)
      .filter((link) => !/\/sessions\/session-note\/[a-zA-Z0-9-]+$/.test(link)) // Filter out links to document session

    this.fetchAndCacheDynamics('ISE', links)
  }

  /**
   * Sync the logout page and its statics.
   * @returns Promise<void>
   */
  private async syncLogout() {
    const htmlResp = await fetchWithRetry(
      '/accounts/logout/',
      this.retryAttempts,
    )
    const cache = await caches.open('twyll-offline')
    cache.put('/accounts/logout/', htmlResp.clone())

    return this.fetchAndCacheStatics('logout page', ['/static/js/logout.js'])
  }

  /**
   * Sync the waiting page and its bundle
   */
  private async syncWaitingPage() {
    const routeWaitingJsBundle = `/static/assets/js/waiting.${this.bundleHash}.js`

    const html = await fetchWithRetry('/sessions/waiting', this.retryAttempts)
    const reactApp = await fetchWithRetry(
      routeWaitingJsBundle,
      this.retryAttempts,
    )
    const translations = await fetchWithRetry(
      '/static/locales/en-us/offline.json',
      this.retryAttempts,
    )

    const [cacheTwyllOffline, cacheStaticResources] = await Promise.all([
      caches.open('twyll-offline'),
      caches.open('static-resources'),
    ])

    cacheTwyllOffline.put('/session/waiting', html.clone())
    cacheStaticResources.put(routeWaitingJsBundle, reactApp.clone())
    cacheStaticResources.put(
      '/static/locales/en-us/offline.json',
      translations.clone(),
    )
  }

  /**
   * Sync the sessions idx page
   */
  private async syncSessionsIdx() {
    await this.fetchAndCacheDynamics('Sessions Idx', ['/sessions/new'])

    return this.fetchAndCacheStatics('Sessions Idx', [
      `/static/assets/js/sessions.${this.bundleHash}.js`,
      '/static/locales/en-us/sessions.json',
    ])
  }

  /**
   * Send a POST request to create a new session record in db which was created in offline mode
   */
  private async syncNewSession() {
    const newSessionData = localStorage.getItem('new-session-to-post')
    if (newSessionData) {
      try {
        const { clientId, formattedData: payload } = JSON.parse(newSessionData)
        const response = await fetch(
          new URL(
            `${window.location.origin}/api/offline/client/${clientId}/new_session/`,
          ),
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': window.CSRFTOKEN,
              Accept: 'application/json',
            },
            body: JSON.stringify(payload),
          },
        )

        if (response.status !== 201) {
          throw new Error(`failed to create a new offline session`)
        } else {
          localStorage.removeItem('new-session-to-post')
        }
      } catch (e) {
        if (e instanceof Error) {
          const error = new Error(e.message, { cause: e })
          error.name = `Error during session sync: ${error.name}`

          ErrorMonitoring.captureException(error)
        }
      }
    }
  }

  /**
   * Caching page to render ISE for offline created session
   */
  private async syncOfflineSessionPage() {
    const bundleHash = process.env.BUNDLE_HASH // 'bundle' || 'v4uuid'
    const routeOfflineSessionJsBundle = `/static/assets/js/offline_session.${bundleHash}.js`

    const html = await fetchWithRetry(
      '/sessions/offline-session',
      this.retryAttempts,
    )
    const reactApp = await fetchWithRetry(
      routeOfflineSessionJsBundle,
      this.retryAttempts,
    )

    const [cacheTwyllOffline, cacheStaticResources] = await Promise.all([
      caches.open('twyll-offline'),
      caches.open('static-resources'),
    ])

    cacheTwyllOffline.put('/session/offline-session', html.clone())
    cacheStaticResources.put(routeOfflineSessionJsBundle, reactApp.clone())
  }

  /*
    Sync ISE for upcoming sessions
  */
  private async syncUpcomingSessionsISE(sessions: SessionSnakeCase[]) {
    const filterSessions = sessions
      .filter(
        ({ session_type }: SessionSnakeCase) => session_type === 'session',
      )
      .map(({ uuid }: SessionSnakeCase) => `/sessions/${uuid}`)

    await this.fetchAndCacheDynamics('upcoming ISE', filterSessions)
  }

  /**
   * Sync upcoming sessions
   */
  private async syncUpcomingSessions() {
    try {
      const upcomingSessions = await getUpcomingSessions()
      if (upcomingSessions?.sessions) {
        await this.syncUpcomingSessionsISE(upcomingSessions.sessions)
      }
    } catch (e) {
      if (e instanceof Error) {
        const errorMessage = e instanceof Error ? e.message : e
        throw new Error(errorMessage, { cause: e })
      }
    }
  }

  /**
   * Sync the encrypted treatment plan
   */
  private async syncTreatmentPlan() {
    try {
      let response
      try {
        response = await fetchWithRetry(
          '/api/offline/client/',
          this.retryAttempts,
        )
      } catch (e) {
        throw new Error('Failed to fetch treatment plan', { cause: e })
      }

      if (response.status !== 200) {
        throw new Error(
          `Failed to fetch treatment plan: ${response.status} ${response.statusText}`,
        )
      }

      let treatmentPlan
      try {
        treatmentPlan = await response.json()
      } catch (e) {
        throw new Error('Failed to parse treatment plan JSON', { cause: e })
      }

      let treatmentPlanJSON
      try {
        treatmentPlanJSON = JSON.stringify(treatmentPlan)
      } catch (e) {
        throw new Error('Failed to stringify treatment plan', { cause: e })
      }

      let cryptoKey
      try {
        cryptoKey = await this.symmetricKeyManager.get()
      } catch (e) {
        throw new Error('Failed to retrieve symmetric key', { cause: e })
      }

      let encryptedTreatmentPlan
      try {
        encryptedTreatmentPlan = await encryptSymmetrically(
          cryptoKey,
          treatmentPlanJSON,
        )
      } catch (e) {
        throw new Error('Failed to encrypt treatment plan', { cause: e })
      }

      const headers = {
        'Content-Type': 'text/plain',
        'Content-Length': encryptedTreatmentPlan.length.toString(),
        'Response-Type': 'basic',
        Vary: 'Accept-Language, Cookie',
      }

      let treatmentPlanResponse
      try {
        treatmentPlanResponse = new Response(encryptedTreatmentPlan, {
          status: 200,
          headers,
        })
      } catch (e) {
        throw new Error('Failed to create Response object', { cause: e })
      }

      let cacheTwyllOffline
      try {
        cacheTwyllOffline = await caches.open('twyll-offline')
      } catch (e) {
        throw new Error('Failed to open cache', { cause: e })
      }

      try {
        await cacheTwyllOffline.put(
          '/api/offline/client/',
          treatmentPlanResponse,
        )
      } catch (e) {
        throw new Error('Failed to cache treatment plan response', { cause: e })
      }
    } catch (e) {
      if (e instanceof Error) {
        const error = new Error(e.message, { cause: e })
        error.name = `Error during treatment plan sync: ${e.name}`

        ErrorMonitoring.captureException(error)
      }
    }
  }

  /**
   * Sync the symmetric key used for encrypting offline events
   */
  private async syncSymmetricKey() {
    // DBW 6/21/24 -- this returns the key, but we only need to refresh the cache,
    // so we drop the return value on the floor
    try {
      await this.symmetricKeyManager.fetchAndCache()
    } catch (e) {
      this.syncEventBusManager.dispatch(
        new CustomEvent(SYNC_EVENT_TYPES.ERROR, {
          detail: { error: e },
        }),
      )
      Sentry.captureException(e)
    }
  }

  /**
   * Sync the clients page and its API calls
   * @returns Promise<void>
   */
  private async syncMyClientsPage() {
    await this.fetchAndCacheDynamics('Clients page', [
      '/clients/',
      '/api/clients-api/v1/clients/filters/',
      '/api/clients-api/v1/clients/?search=',
    ])

    return this.fetchAndCacheStatics('clients page', [
      '/static/locales/en-us/clients.json',
      `/static/assets/js/clients.${this.bundleHash}.js`,
    ])
  }

  /**
   * Sync All clients page and its API calls
   * @returns Promise<void>
   */
  private async syncAllClientsPage() {
    await this.fetchAndCacheDynamics('All Clients page', [
      '/clinics/clients',
      '/api/clients-api/v1/clients/associated-filters/',
      '/api/clients-api/v1/clients/associated/?search=',
    ])

    return this.fetchAndCacheStatics('all clients page', [
      '/static/locales/en-us/common.json',
      '/static/locales/en-us/organizations.json',
      '/static/locales/en-us/clients.json',
      `/static/assets/js/all_clients.${this.bundleHash}.js`,
    ])
  }

  private async syncClientsPage() {
    // Fetch user data from the endpoint
    const response = await fetchWithRetry(
      '/api/users/v1/user/',
      this.retryAttempts,
    )
    if (!response.ok) {
      throw new Error('Failed to fetch user permissions')
    }

    // Parse the user data from the response
    const userData = await response.json()

    // Check permissions and roles to determine which sync client method to call
    if (userData.policies?.can_list_clinics) {
      await this.syncAllClientsPage()
    } else if (
      userData.roles?.includes('Therapist') ||
      userData.roles?.includes('Supervisor')
    ) {
      await this.syncMyClientsPage()
    } else {
      console.warn('User does not have permission to sync any clients page')
    }
  }

  /**
   * Check if user has session list permission
   * @returns Promise<boolean>
   */
  private async hasSessionSyncPermission(): Promise<boolean> {
    if (this.sessionSyncPermission !== null) {
      return this.sessionSyncPermission
    }
    const response = await fetchWithRetry(
      '/api/users/v1/user/',
      this.retryAttempts,
    )
    if (!response.ok) {
      throw new Error('Failed to fetch user permissions')
    }
    const userData = await response.json()

    this.sessionSyncPermission = !!userData.policies?.can_list_sessions
    return this.sessionSyncPermission
  }

  /**
   * Sync shared assets like CSS and JS that are used by many pages
   * @returns Promise<void>
   */
  private async syncSharedAssets() {
    return this.fetchAndCacheStatics('shared assets', [
      `/static/assets/js/offline.${this.bundleHash}.js`,
      '/static/js/analytics.js',
      '/static/js/copy-paste.js',
      '/static/assets/images/[chunkhash].gif',
    ])
  }

  /**
   * Fetch and cache a list of static resources
   * @param pageName The name of the page we're fetching and caching for
   * @param resources The list of static resource urls to fetch
   * @returns Promise<void>
   */
  private async fetchAndCacheStatics(pageName: string, resources: string[]) {
    return this.fetchAndCache(pageName, resources, 'static-resources')
  }

  /**
   * Fetch and cache a list of dynamic resources
   * @param pageName The name of the page we're fetching and caching for
   * @param resources The list of dynamic resource urls to fetch
   * @returns
   */
  private async fetchAndCacheDynamics(pageName: string, resources: string[]) {
    return this.fetchAndCache(pageName, resources, 'twyll-offline')
  }

  /**
   * Fetch and cache a list of urls
   * @param pageName The name of the page we're fetching and caching for
   * @param resources The list of urls to fetch
   * @param cacheName The cache to cache the responses in
   * @returns Promise<void>
   */
  private async fetchAndCache(
    pageName: string,
    resources: string[],
    cacheName: string,
  ) {
    const responses: Response[] = []

    // for of - for making all requests sequentially
    // to avoid overloading server
    for (const resource of resources) {
      responses.push(await fetchWithRetry(resource, this.retryAttempts))
    }

    if (responses.some((response) => response.status >= 400)) {
      this.syncEventBusManager.dispatch(
        new CustomEvent(SYNC_EVENT_TYPES.ERROR, {
          detail: { message: `Failed to sync ${pageName}` },
        }),
      )
      return
    }

    const cache = await caches.open(cacheName)
    responses.forEach((resp) => {
      const clonedResponse = resp.clone()
      if (clonedResponse.ok && clonedResponse.status !== 206) {
        cache.put(resp.url, clonedResponse)
      }
    })
  }

  private isUserLoggedIn() {
    const verificationTokenCookieValue = getCookieValue('verification_token')

    return !!verificationTokenCookieValue
  }

  private async doInitialSync() {
    const isSessionIdxEnabled = growthbook.getFeatureValue(
      'beavers_new_session_list',
      false,
    )
    await this.syncSymmetricKey()
    await this.syncNewSession()
    await this.syncISEEvents()
    await this.syncSharedAssets()

    if (await this.hasSessionSyncPermission()) {
      await this.syncSessions()
      await this.syncWaitingPage()
      await this.syncOfflineSessionPage()
    } else {
      console.warn('User does not have permission to sync session pages.')
    }

    if (isSessionIdxEnabled) {
      await this.syncUpcomingSessions()
      await this.syncSessionsIdx()
    }
    await this.syncClientsPage()
    await this.syncLogout()
    await this.syncTreatmentPlan()
  }

  private async doHourlySync() {
    const isSessionIdxEnabled = growthbook.getFeatureValue(
      'beavers_new_session_list',
      false,
    )
    if (await this.hasSessionSyncPermission()) {
      await this.syncSessions()
    } else {
      console.warn('User does not have permission to sync session pages.')
    }

    isSessionIdxEnabled && (await this.syncUpcomingSessions())
    await this.syncTreatmentPlan()
  }

  private async syncISEEvents() {
    await navigator.locks.request('ise-events-sync', async () => {
      try {
        await this.localDataManager.initDB()
        await this.localDataManager.removeExpiredData()
        await this.localDataManager.drainQueue()
      } catch (e) {
        Sentry.captureException(e)
      }
    })
  }

  private setupEventListeners() {
    this.syncEventBusManager.addEventListener(
      SYNC_EVENT_TYPES.MANUAL,
      async () => {
        this.syncEventBusManager.dispatch(
          new CustomEvent(SYNC_EVENT_TYPES.START, {
            detail: { message: 'starting background sync' },
          }),
        )

        try {
          await this.doInitialSync()
        } catch (e) {
          this.syncEventBusManager.dispatch(
            new CustomEvent(SYNC_EVENT_TYPES.ERROR, {
              detail: { error: e },
            }),
          )
        }

        this.syncEventBusManager.dispatch(
          new CustomEvent(SYNC_EVENT_TYPES.SUCCESS, {
            detail: { message: 'background sync completed successfully' },
          }),
        )
      },
    )

    this.syncEventBusManager.addEventListener(
      SYNC_EVENT_TYPES.RECONNECT,
      async () => {
        this.syncEventBusManager.dispatch(
          new CustomEvent(SYNC_EVENT_TYPES.START, {
            detail: { message: 'starting sessions sync' },
          }),
        )

        try {
          await this.syncISEEvents()
        } catch (e) {
          this.syncEventBusManager.dispatch(
            new CustomEvent(SYNC_EVENT_TYPES.ERROR, {
              detail: { error: e },
            }),
          )
        }

        this.syncEventBusManager.dispatch(
          new CustomEvent(SYNC_EVENT_TYPES.SUCCESS, {
            detail: { message: 'sessions sync completed successfully' },
          }),
        )
      },
    )
  }
}

function getCookieValue(cookieName: string) {
  const cookieValue = document.cookie
    .split('; ')
    .find((row) => row.startsWith(`${cookieName}=`))
    ?.split('=')[1]

  return cookieValue
}
